import React, {useEffect, useMemo, useState} from 'react';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {skipToken} from '@reduxjs/toolkit/query';
import {
  GiveTeamRecognitionFormController,
  GiveTeamRecognitionFormSchema,
  // eslint-disable-next-line max-len
} from '@compt/pages/team-recognition-page/components/give-team-recognition-form/give-team-recognition-form.controller';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Allotment, AllotmentCycleFundingMode} from '@compt/types/allotments';
import {TeamRecognitionUser} from '@compt/types/team-recognition/team-recognition';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptSearchField} from '@compt/common/forms/compt-search-field/compt-search-field';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {ComptFeature} from '@compt/common/compt-feature/compt-feature';
import {SelectedUsers} from '@compt/pages/team-recognition-page/components/give-team-recognition-form/selected-users';
import {
  useGetTeamRecognitionCCRecipientsQuery,
  useGetTeamRecognitionRecipientsQuery,
  useGiveTeamRecognitionMutation,
} from '@compt/app/services/api/team-recognition-slice';
import {
  GiveTeamRecognitionSubmitFormWrapper,
  GiveTeamRecognitionSummaryWrapper,
  // eslint-disable-next-line max-len
} from '@compt/pages/team-recognition-page/components/give-team-recognition-form/give-team-recognition-wrappers';
import {useGetAsyncResultsQuery} from '@compt/app/services/api/async-results-slice';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {ReactHookFormErrors} from '@compt/types/form/react-hook-form-helper-types';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import toast from 'react-hot-toast';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

interface GiveTeamRecognitionFormProps {
  open: boolean;
  hideForm: (allotment?: Allotment) => void;
  openForm: () => void;
  searchParams: URLSearchParams;
  queryKey: string;
  teamBonusAllotments?: Allotment[];
}

const DEFAULT_USER_SEARCH_PAGE_SIZE = 15;

export const GiveTeamRecognitionForm = (props: GiveTeamRecognitionFormProps) => {
  const {searchParams, teamBonusAllotments, open, queryKey} = props;
  const sessionQuery = useGetSessionQuery();
  const allotmentQuery = useGetAllotmentsQuery(sessionQuery.data?.user_id ?? skipToken);
  const [giveRecognition, result] = useGiveTeamRecognitionMutation();
  const [recipientSearchQuery, setRecipientSearchQuery] = useState('');
  const [ccRecipientSearchQuery, setCCRecipientSearchQuery] = useState('');
  const [pollAsyncResult, setPollAsyncResult] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);

  const asyncResultQuery = useGetAsyncResultsQuery(taskId ?? skipToken, {
    /**
     * When the polling interval is set to 0, polling will stop but the data will remain.
     */
    pollingInterval: pollAsyncResult ? 500 : 0,
  });

  let recognitionAllotments: Allotment[] = [];

  if (allotmentQuery.data) {
    recognitionAllotments = allotmentQuery.data.team_bonus_allotments.filter(
      (allotment: Allotment) => {
        if (allotment.balance_amount > 0) {
          return true;
        }

        return allotment.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding;
      },
    );
  }

  // Remove any open toasts
  useEffect(() => {
    if (open) {
      toast.remove();
    }
  }, [open]);

  const {register, handleSubmit, watch, formState, control, getValues, resetField, trigger, reset} =
    useForm<GiveTeamRecognitionFormSchema>({
      defaultValues: {
        shareRecognition: true,
        recognitionAmountDropdown: null,
      },
    });

  const recognitionAllotmentWatch = watch('recognitionAllotment');
  const recognitionRecipientsWatch = watch('recognitionRecipients');

  const recipientsQuery = useGetTeamRecognitionRecipientsQuery(
    recognitionAllotmentWatch
      ? {
          q: recipientSearchQuery,
          offset: 0,
          limit: DEFAULT_USER_SEARCH_PAGE_SIZE,
          cycle_id: recognitionAllotmentWatch.cycle.id,
        }
      : skipToken,
  );

  const ccRecipientsQuery = useGetTeamRecognitionCCRecipientsQuery(
    featureEnabled(FeatureFlags.RECOGNITION_EMAIL_CC)
      ? {
          q: ccRecipientSearchQuery,
          offset: 0,
          limit: DEFAULT_USER_SEARCH_PAGE_SIZE,
        }
      : skipToken,
  );

  const isNonMonetary = recognitionAllotmentWatch
    ? recognitionAllotmentWatch.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding
    : false;
  const title = isNonMonetary ? 'Give Shout Out!' : 'Give recognition';

  const maxAllowedAmount = useMemo(() => {
    let allowedBalanceAmount = recognitionAllotmentWatch?.balance_amount ?? 0;
    const factor = Math.pow(10, 2); // factor of 2 decimal points

    // The allowed amount balance changes to account for the number of recipients attached
    if (allowedBalanceAmount > 0 && recognitionRecipientsWatch?.length) {
      allowedBalanceAmount = allowedBalanceAmount / recognitionRecipientsWatch.length;
    }

    return Math.floor(allowedBalanceAmount * factor) / factor;
  }, [recognitionAllotmentWatch, recognitionRecipientsWatch]);

  // Populate allotment field if allotment id from search param exists
  useEffect(() => {
    if (!open) {
      reset();
      return;
    }

    const allotmentId = searchParams.get(queryKey);
    const allotment = GiveTeamRecognitionFormController.getSelectedAllotment(
      allotmentId,
      teamBonusAllotments,
    );
    if (allotment) {
      reset({recognitionAllotment: allotment, shareRecognition: true});
    } else {
      reset({recognitionAllotment: null, shareRecognition: true});
    }
  }, [searchParams, reset, teamBonusAllotments, queryKey, open]);

  useEffect(() => {
    GiveTeamRecognitionFormController.resetForm(resetField);
  }, [recognitionAllotmentWatch, resetField]);

  useEffect(() => {
    const previousValueDropdown = getValues().recognitionAmountDropdown;
    let previousValue = getValues().recognitionAmountInput;
    let inputType = 'recognitionAmountInput';
    if (!previousValue) {
      previousValue = previousValueDropdown || undefined;
      inputType = 'recognitionAmountDropdown';
    }
    GiveTeamRecognitionFormController.resetAmountInputs(resetField, inputType, previousValue);
    if (getValues().recognitionRecipients?.length && previousValue ? previousValue > 0 : false) {
      trigger('recognitionAmountInput');
      trigger('recognitionAmountDropdown');
    }
  }, [recognitionRecipientsWatch, resetField, trigger, getValues]);

  useEffect(() => {
    if (taskId) {
      if (!asyncResultQuery.data && !pollAsyncResult) {
        // we have not yet received the data...
        setPollAsyncResult(true);
      }

      if (asyncResultQuery.data?.status_code === 200) {
        setPollAsyncResult(false);
        setTaskId(null);
        props.hideForm(recognitionAllotmentWatch || undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncResultQuery.data, taskId, pollAsyncResult, props]);

  const handleTaskSubmittedSuccessfully = (taskId: string) => {
    setTaskId(taskId);
    triggerCustomToast(
      'success',
      'Successfully gave recognition',
      'We’ll let your teammate know just how much you appreciate them.',
      undefined,
      'Give another',
      props.openForm,
      () => {},
      40000, // 40 seconds
    );
  };

  const onSubmit: SubmitHandler<GiveTeamRecognitionFormSchema> = (data) => {
    GiveTeamRecognitionFormController.submitRecognition(
      data,
      giveRecognition,
      handleTaskSubmittedSuccessfully,
    );
    reset();
  };

  const AmountInput = () => {
    const selectedAllotment: Allotment | undefined | null = getValues().recognitionAllotment;
    if (selectedAllotment?.cycle?.amount_options?.length) {
      return (
        <ComptDropDownField
          id="team-recognition-amount-dropdown"
          name="recognitionAmountDropdown"
          value={null}
          label="Amount"
          getDisplayText={(option) =>
            formatCurrencyFromCountryCode(option, undefined, selectedAllotment.currency)
          }
          getKey={(option) => option}
          options={selectedAllotment.cycle.amount_options.filter((option) =>
            recognitionRecipientsWatch?.length
              ? parseInt(option) <=
                selectedAllotment.balance_amount / recognitionRecipientsWatch?.length
              : true,
          )}
          invalidOptions={selectedAllotment.cycle.amount_options.filter((option) =>
            recognitionRecipientsWatch?.length
              ? parseInt(option) >
                selectedAllotment.balance_amount / recognitionRecipientsWatch?.length
              : false,
          )}
          placeholder={'$ 0.00'}
          register={register}
          control={control}
          validation={{
            required: 'Amount is required',
            max: {
              value: maxAllowedAmount,
              message: 'Amount too large',
            },
          }}
          onChange={() => trigger('recognitionAmountDropdown')}
          errors={formState.errors.recognitionAmountDropdown}
        />
      );
    }

    return (
      <ComptCurrencyField
        id="team-recognition-amount-input"
        name="recognitionAmountInput"
        label="Amount"
        value={null}
        register={register}
        control={control}
        validation={{
          required: 'Amount is required',
          min: 0.01,
          max: {
            value: maxAllowedAmount,
            message: 'Amount too large',
          },
        }}
        onChange={() => trigger('recognitionAmountInput')}
        errors={formState.errors.recognitionAmountInput}
        givenCurrency={selectedAllotment?.currency}
      />
    );
  };

  const CompanyValuesDropdown = () => {
    const selectedAllotment: Allotment | null = getValues().recognitionAllotment;
    const selectedRecipients: TeamRecognitionUser[] | undefined = getValues().recognitionRecipients;

    let label = 'What are you recognizing this member for?';
    if (selectedRecipients && selectedRecipients.length > 1) {
      label = 'What are you recognizing these members for?';
    }

    if (
      selectedAllotment &&
      selectedAllotment.cycle.company_values &&
      selectedAllotment.cycle.company_values.length > 0
    ) {
      return (
        <ComptDropDownField
          id="give-team-recognition-values-dropdown"
          name="recognitionCompanyValue"
          label={label}
          placeholder="Select reason"
          getDisplayText={(option) => option?.value || ''}
          getKey={(option) => option.id}
          errors={formState.errors.recognitionCompanyValue}
          register={register}
          control={control}
          options={selectedAllotment.cycle.company_values}
          validation={{required: 'Recognition value is required'}}
          by={(a, b) => a?.id === b?.id}
        />
      );
    }

    return null;
  };

  const formLoading: boolean =
    result.isLoading ||
    formState.isSubmitting ||
    asyncResultQuery.isLoading ||
    asyncResultQuery.isFetching ||
    !!taskId ||
    pollAsyncResult;

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header
        title={title}
        setOpen={() => props.hideForm()}
        headerIcon={{id: 'star-icon'}}
      />
      <ComptSidePanel.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="p-400">
          <fieldset disabled={formState.isSubmitting}>
            <ComptDropDownField<Allotment, GiveTeamRecognitionFormSchema, 'recognitionAllotment'>
              id="give-team-recognition-allotment-dropdown"
              name="recognitionAllotment"
              label="Recognition type"
              placeholder="Select a recognition program"
              getSecondaryText={(option) => {
                const isNonMonetary =
                  option.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding;

                return isNonMonetary ? 'Non-monetary' : 'Monetary';
              }}
              getDisplayText={(option) => option.cycle.name}
              getKey={(option) => option.id}
              errors={formState.errors.recognitionAllotment as ReactHookFormErrors}
              register={register}
              control={control}
              options={recognitionAllotments}
              validation={{required: 'Stipend is required'}}
              by="id"
            />
            <ComptSearchField
              id="give-recognition-recipient-search-field"
              name="recognitionRecipients"
              value={[]}
              by="email"
              errors={formState.errors.recognitionRecipients as ReactHookFormErrors}
              loading={recipientsQuery.isLoading}
              options={recipientsQuery.data?.results || []}
              register={register}
              control={control}
              onQueryChanged={setRecipientSearchQuery}
              getKey={(option) => option.email}
              getDisplayText={(option) => option.full_name}
              label="Give recognition to"
              getSecondaryText={(option) => option.email}
              renderSelectionDisplay={(selection, onRemove) => (
                <SelectedUsers users={selection} onRemove={(user) => onRemove(user)} />
              )}
              validation={{required: 'At least one recipient is required'}}
              placeholder="Select an individual"
              disabled={!recognitionAllotmentWatch}
            />
            {CompanyValuesDropdown()}
            <ComptTextAreaField
              id="team-recognition-description"
              name="recognitionDescription"
              label="Description"
              // eslint-disable-next-line max-len
              placeholder="Explain why you're giving recognition. This is an opportunity to make someone feel special."
              register={register}
              // control={control}
              rows={5}
              validation={{
                required: 'Description is required',
                maxLength: {
                  value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                  message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
                },
              }}
              errors={formState.errors.recognitionDescription}
              maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
            />
            {!isNonMonetary && AmountInput()}
            {!isNonMonetary && <GiveTeamRecognitionSummaryWrapper control={control} />}
            <div className={isNonMonetary ? 'pt-0' : 'pt-400'}>
              <ComptFeature featureFlag={FeatureFlags.RECOGNITION_EMAIL_CC}>
                <div className="mb-60">
                  <ComptSearchField
                    id="give-recognition-cc-recipient-search-field"
                    name="recognitionCCRecipients"
                    value={[]}
                    by="email"
                    loading={ccRecipientsQuery.isLoading}
                    options={ccRecipientsQuery.data?.results || []}
                    register={register}
                    control={control}
                    onQueryChanged={setCCRecipientSearchQuery}
                    getKey={(option) => option.email}
                    getDisplayText={(option) => option.full_name}
                    label="CC Recognition email"
                    placeholder="Select a user to CC"
                    getSecondaryText={(option) => option.email}
                    renderSelectionDisplay={(selection, onRemove) => (
                      <SelectedUsers users={selection} onRemove={(user) => onRemove(user)} />
                    )}
                  />
                </div>
              </ComptFeature>
            </div>
          </fieldset>
        </form>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex flex-col">
          <ComptCheckboxField
            name="shareRecognition"
            value={true}
            label="Share recognition in company feed"
            register={register}
            className="mb-200"
          />
          <div className="flex space-x-200">
            <GiveTeamRecognitionSubmitFormWrapper
              control={control}
              onSubmitClick={handleSubmit(onSubmit)}
              disabled={formLoading || !formState.isValid}
              isNonMonetary={isNonMonetary}
            />
            <ComptButton
              disabled={formLoading}
              buttonType={ComptButtonType.OUTLINED}
              onClick={() => props.hideForm()}
              className="w-full mr-1"
            >
              Cancel
            </ComptButton>
          </div>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
