import React, {useMemo, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/query';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetTeamRosterSyncAttemptListQuery} from '@compt/app/services/api/team-roster-sync-attempts-slice';

// Hooks and methods
import {TeamRosterSyncAttemptTableController} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-attempt/team-roster-sync-attempt-table.controller';
import {useDebounce} from '@uidotdev/usehooks';
import {useNavigate} from 'react-router-dom';

// Components
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';

// Types
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';
import {TeamRosterSyncAttempt} from '@compt/types/team-roster-syncs/team-roster-syncs';

export const TeamRosterSyncAttemptTable = () => {
  const controller = new TeamRosterSyncAttemptTableController();
  const {updateQueryParamsOnValuesChanged} = controller;

  const navigate = useNavigate();
  const session = useGetSessionQuery();
  const userId = session.data?.user_id;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const [syncAttemptsQueryValues, setSyncAttemptsQueryValues] = useState<
    FormattedFilterObject | undefined
  >();
  const debouncedQueryValues = useDebounce(syncAttemptsQueryValues, 300);
  const teamRosterSyncAttemptListQuery = useGetTeamRosterSyncAttemptListQuery(
    {params: debouncedQueryValues},
    {skip: !userId},
  );

  const onActionClicked = (syncAttempt: TeamRosterSyncAttempt) => {
    navigate(`/sync-attempts/${syncAttempt.id}`);
  };

  const columnDefinition = controller.getColumnDefinition(onActionClicked);
  const filterConfiguration = controller.getFilterConfiguration();
  const initialFilterValues = TeamRosterSyncAttemptTableController.getInitialFilterValues();

  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No team roster sync attempts were found with the current filters.'
        : 'No team roster sync attempts exist yet',
    [filtersApplied],
  );

  return (
    <ComptLoadingIndicator
      isLoading={companyQuery.isLoading || teamRosterSyncAttemptListQuery.isLoading}
      className="pt-72"
    >
      {companyQuery.data && teamRosterSyncAttemptListQuery.data && (
        <div>
          <ComptTable
            company={companyQuery.data}
            tableId="team-roster-sync-attempts-table"
            className="w-full"
            data={teamRosterSyncAttemptListQuery.data?.results ?? []}
            dataLoading={companyQuery.isFetching || teamRosterSyncAttemptListQuery.isFetching}
            noDataTitleText={noDataTitleText}
            allowShowHide={false}
            columnDefinition={columnDefinition}
            filterConfiguration={filterConfiguration}
            initialFilterValues={initialFilterValues}
            allowPagination={true}
            totalCount={teamRosterSyncAttemptListQuery.data?.count}
            itemsPerPage={MAX_ITEMS_PER_PAGE}
            onChangeQueryValues={(filterValues, pagination, ordering) =>
              updateQueryParamsOnValuesChanged(
                companyId,
                setSyncAttemptsQueryValues,
                setFiltersApplied,
                pagination,
                filterValues,
                ordering,
              )
            }
            onRowClicked={(row) => onActionClicked(row)}
            stickyLastColumn
          />
        </div>
      )}
    </ComptLoadingIndicator>
  );
};
