import React, {useState} from 'react';
import {BusinessExpenseReportsTable} from '@compt/pages/business-expenses/business-expense-reports-page/business-expense-reports-table.container';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {useCreateBusinessExpenseReportMutation} from '@compt/app/services/api/business-expense-reports-slice';
import {BusinessReportSidePanel} from '../components/business-report-side-panel';
import {FieldValues} from 'react-hook-form';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {useNavigate} from 'react-router-dom';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

export const BusinessExpenseReportsPage = () => {
  const [openCreateSidePanel, setOpenCreateSidePanel] = useState(false);
  const navigate = useNavigate();

  const isMobileView = useIsMobileView();

  const [createExpenseReport, {isLoading}] = useCreateBusinessExpenseReportMutation();
  const session = useGetSessionQuery();
  const hasRequiredRole = session.data?.roles.includes(USER_ROLES.businessExpenseEligible);

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  const onCreateReportSubmit = (form: FieldValues) => {
    const submission: Partial<BusinessExpenseReport> = {
      title: form.title,
      description: form.description,
    };

    createExpenseReport(submission).then((body) => {
      if ('data' in body) {
        const reportId = body.data.report.id;
        navigate(`/business-expenses/${reportId}`);
        triggerCustomToast('success', 'Successfully created report');
      }
    });
  };

  return (
    <>
      <BusinessReportSidePanel
        open={openCreateSidePanel}
        setOpen={setOpenCreateSidePanel}
        onSubmit={onCreateReportSubmit}
        mutationLoading={isLoading}
      />
      <ComptPage
        title="Business Expenses"
        subtitle="View or submit expense reports"
        className="h-dvh pb-500 sm:pb-000"
        includeBottomHR={false}
        action={
          session.isSuccess &&
          !isMobileView && (
            <ComptButton
              className="sm:w-auto w-3/5 mb-2"
              iconId={ComptButtonIcon.PLUS}
              onClick={() => setOpenCreateSidePanel(true)}
            >
              New expense report
            </ComptButton>
          )
        }
      >
        <BusinessExpenseReportsTable />
      </ComptPage>
      {isMobileView && (
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform z-10
          -translate-x-1/2 w-full p-300 border-t border-t-gray-300`}
        >
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.PLUS}
            data-testid="new-expense-report-mobile-button"
            className="w-full"
            onClick={() => setOpenCreateSidePanel(true)}
          >
            New expense report
          </ComptButton>
        </div>
      )}
    </>
  );
};
