import React from 'react';
import {MyStipendsPage} from './pages/employee-stipends/my-stipends-page/my-stipends-page';
import './index.scss';
import {EmployeeFaqPage} from './pages/employee-faq-page/employee-faq-page';
import {createHashRouter, RouteObject} from 'react-router-dom';
import {App} from './App';
import {Protected} from '@compt/features/auth/protected';
import {ActivityPage} from '@compt/pages/employee-stipends/activity-page/activity-page';
import {BusinessExpenseReportsPage} from './pages/business-expenses/business-expense-reports-page/business-expense-reports-page';
import {BusinessExpensePage} from './pages/business-expenses/business-expense-page/business-expense-page';
import {TeamRecognitionPage} from './pages/team-recognition-page/team-recognition-page';
import {AssignmentsPage} from './pages/assignments-page/assignments-page';
import {OnboardingBasePage} from '@compt/pages/employee-onboarding-page/onboarding-base-page';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {RedirectPage} from '@compt/pages/redirect-page/redirect-page';
import {AdminBusinessExpenseReportsPage} from './pages/admin-pages/admin-business-expenses/admin-business-expense-reports-page';
import {AdminBusinessExpensePage} from './pages/admin-pages/admin-business-expenses/admin-business-expense-page';
import {AdminSearchBusinessExpensesPage} from './pages/admin-pages/admin-business-expenses/admin-search-business-expenses-page';
import {AdminCustomizableNotificationsPage} from './pages/admin-pages/admin-customizable-notifications/admin-customizable-notifications-page';
import {TeamRosterSyncAttemptPage} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-attempt/team-roster-sync-attempt-page';
import {TeamRosterSyncResultPage} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-results/team-roster-sync-results-page';

type ComptRouteObject = RouteObject & {notProtected?: boolean};

const protect: (route: ComptRouteObject) => ComptRouteObject = (route: ComptRouteObject) => {
  if (route.notProtected) {
    return route;
  }

  route.element = <Protected>{route.element}</Protected>;

  return route;
};

/**
 * Helper to "protect" elements when appropriate.
 * Note that by default, components will be protected.
 *
 * @see ComptRouteObject
 * @see protect
 * @param routes
 */
const handleProtectedRoutes: (routes: ComptRouteObject[]) => ComptRouteObject[] = (routes) =>
  routes.map(protect);

/**
 * Compt router configuration.
 *
 * Add routes to different Compt pages here.
 *
 * @see handleProtectedRoutes
 */
export const COMPT_ROUTER = createHashRouter(
  handleProtectedRoutes([
    {
      path: '/',
      element: <App />,
      errorElement: <Error404Page />,
      children: handleProtectedRoutes([
        {
          path: '*',
          element: <Error404Page />,
        },
        {
          path: '/',
          element: <MyStipendsPage />,
        },
        {
          path: '/accounts/delegate',
          element: <AssignmentsPage />,
        },
        {
          path: 'activity',
          element: <ActivityPage />,
        },
        {
          path: '/faq',
          element: <EmployeeFaqPage />,
        },
        {
          path: 'business-expenses',
          element: <BusinessExpenseReportsPage />,
        },
        {
          path: '/business-expenses/:reportId',
          element: <BusinessExpensePage />,
        },
        {
          path: '/team-recognition',
          element: <TeamRecognitionPage />,
        },
        {
          path: '/employee-onboarding',
          element: <OnboardingBasePage />,
        },
        {
          path: '/sync-attempts',
          element: <TeamRosterSyncAttemptPage />,
        },
        {
          path: '/sync-attempts/:syncAttemptId',
          element: <TeamRosterSyncResultPage />,
        },
        {
          path: '/customizable-notifications',
          element: <AdminCustomizableNotificationsPage />,
        },
        {
          path: '/review-business-expenses',
          element: <AdminBusinessExpenseReportsPage />,
        },
        {
          path: '/search-business-expenses',
          element: <AdminSearchBusinessExpensesPage />,
        },
        {
          path: '/review-business-expenses/:reportId',
          element: <AdminBusinessExpensePage />,
        },
        // The following paths are admin paths not yet implemented in the NDS. The user will be redirected to the
        // same URL but with 'manage' instead of 'app'.
        {
          path: '/stipends',
          element: <RedirectPage basePath="manage" />,
        },
        {
          path: '/review-business-expenses',
          element: <RedirectPage basePath="manage" />,
        },
        {
          path: '/review-business-expenses/:reportId',
          element: <RedirectPage basePath="manage" />,
        },
      ]),
    },
  ]),
);
