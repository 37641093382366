import React, {useState, useEffect, useMemo, Dispatch, SetStateAction, useContext} from 'react';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {
  SaveClaimFormFieldValues,
  SaveClaimSidePanelController,
} from '../compt-save-claim-side-panel/compt-save-claim-side-panel.controller';
import {useForm} from 'react-hook-form';
import {StipendFormContent} from '../compt-save-claim-side-panel/stipend-form-content';
import {ExpenseStatus, StipendExpense} from '@compt/types/stipend-expense';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {ClaimEditHistory} from './claim-edit-history';
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {DeleteStipendExpenseModal} from './delete-stipend-expense-modal';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';
import {
  useGetStipendExpenseQuery,
  useUpdateStipendExpenseMutation,
} from '@compt/app/services/api/stipend-expenses-slice';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL} from '@compt/utils/analytics/types';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {useScrollOutsideContainer} from '@compt/utils/scroll-helper';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {useSearchParams} from 'react-router-dom';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {getPillType} from '@compt/utils/status-pills-helpers';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';

interface ExistingClaimSidePanelProps {
  open: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  'data-testid'?: string;
}

const controller = SaveClaimSidePanelController;

export const ExistingClaimSidePanel = (props: ExistingClaimSidePanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const expenseId = searchParams.get('expense');
  const expenseQuery = useGetStipendExpenseQuery(expenseId ?? skipToken);
  const selectedClaim = expenseQuery.data;

  const [expenseToDelete, setExpenseToDelete] = useState<StipendExpense | null>(null);

  const {contentRef} = useScrollOutsideContainer();

  const sessionQuery = useGetSessionQuery();
  const userId = sessionQuery.data?.user_id;

  const allotmentQuery = useGetAllotmentsQuery(userId ?? skipToken);
  const allotments = allotmentQuery.data?.allotments;
  const allPerkCategories = allotmentQuery.data?.all_perk_categories;

  const [updateStipendExpense, {isLoading: isUpdating}] = useUpdateStipendExpenseMutation();
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);

  const formMethods = useForm<SaveClaimFormFieldValues>();
  const {reset, watch} = formMethods;
  const watchedAllotment = watch('allotment');
  const watchedCategory = watch('perk_category');

  const selectableAllotments = useMemo(
    () =>
      allotments && selectedClaim
        ? allotments.filter(
            (allotment) =>
              allotment.id === selectedClaim.allotments[0].id || allotment.balance_amount > 0,
          )
        : allotments,
    [allotments, selectedClaim],
  );

  // Populate fields with existing claim information
  useEffect(() => {
    if (!selectedClaim) return;
    // Assign supporting documents to react-hook-form field names
    const defaultSupportingDocuments: {[key: string]: string} = {};
    selectedClaim?.supporting_documents.forEach((doc, index) => {
      const fieldName = `supporting_doc_${index}`;
      defaultSupportingDocuments[fieldName] = doc.document_image;
    });
    reset({
      perk_category: controller.getCategoryFromAllotmentById(
        selectedClaim.allotments[0],
        selectedClaim.perk_category,
      ),
      allotment: selectedClaim.allotments[0],
      date_of_expense: selectedClaim.date_of_expense,
      receipt_key: selectedClaim.receipt_image,
      vendor: selectedClaim.vendor_name,
      description: selectedClaim.description,
      amount_of_expense: selectedClaim.amount_of_expense,
      ...defaultSupportingDocuments,
    });
  }, [selectedClaim, reset]);

  // Clear selected allotment if it does not contain selected category
  useEffect(() => {
    if (watchedAllotment && watchedCategory) {
      if (!controller.getCategoryFromAllotmentById(watchedAllotment, watchedCategory.id)) {
        formMethods.resetField('allotment', {defaultValue: null});
      }
    }
  }, [formMethods, watchedCategory, watchedAllotment]);

  function clearSelectedClaim() {
    setSearchParams({});
    props.setEditMode(false);
  }

  function beginEdit() {
    Analytics.sendEvent({
      action: EVENT_ACTION.CLICKED_EDIT_EXPENSE,
      category: EVENT_CATEGORY.EMPLOYEE_MANAGED_EXPENSES,
      label: EVENT_LABEL.INITIAL_EDIT_INTEREST,
    });

    props.setEditMode(true);
  }

  function onSubmitSaveClaim(form: SaveClaimFormFieldValues) {
    if (!selectedClaim) return;

    const adjustedForm = form;

    const supportingDocList = [];

    for (const field in adjustedForm) {
      if (field.startsWith('supporting_doc_') && !!adjustedForm[field]) {
        supportingDocList.push(stripImageNameFromS3URL(adjustedForm[field]));
      }
    }

    const payload = {
      ...adjustedForm,
      perk_category: adjustedForm.perk_category?.id,
      id: selectedClaim.id,
      receipt_key: stripImageNameFromS3URL(adjustedForm.receipt_key),
      supporting_document_keys: supportingDocList,
    } as unknown as StipendExpense;

    updateStipendExpense(payload).then((result) => {
      if ('error' in result) {
        if ('data' in result.error) {
          triggerCustomToast(
            'error',
            'There was a problem updating your claim',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem updating your claim');
        }
        console.error('Error updating claim', result.error);
        return;
      }

      Analytics.sendEvent({
        action: EVENT_ACTION.SAVED_EDIT_EXPENSE,
        category: EVENT_CATEGORY.EMPLOYEE_MANAGED_EXPENSES,
        label: EVENT_LABEL.SAVED_THE_EDITED_STIPEND,
      });

      formMethods.reset();
      triggerCustomToast('success', 'Successfully updated claim');
      props.setEditMode(false);
    });
  }

  const getStatusPill = () => (
    <span className="flex">
      <h2 className="text-color-body1">&nbsp;Status</h2>
      <ComptPill
        className="ml-2"
        pillSize={ComptPillSize.MEDIUM}
        pillType={getPillType(selectedClaim?.status)}
      >
        {controller.getExpenseStatusName(selectedClaim?.status)}
      </ComptPill>
    </span>
  );

  return (
    <>
      <DeleteStipendExpenseModal
        expenseToDelete={expenseToDelete}
        setExpenseToDelete={setExpenseToDelete}
      />
      <ComptSidePanel
        open={props.open}
        className="max-w-[920px]"
        data-testid={props['data-testid']}
      >
        <ComptSidePanel.Header
          title="Expense details"
          statusPill={getStatusPill()}
          setOpen={clearSelectedClaim}
          headerIcon={{id: 'file-icon-blue'}}
        />
        <ComptSidePanel.Content className="sm:overflow-y-hidden">
          <div className="h-full flex flex-col">
            <form className="grow">
              <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
                <div className="flex flex-col w-full md:flex-row">
                  <div className="flex justify-center bg-[#FAF6F1] sm:overflow-y-clip">
                    <div
                      className={`
                      flex flex-col w-full sm:w-[480px] items-end p-6
                    `}
                    >
                      {selectedClaim?.receipt_image && !props.editMode && (
                        <ComptReceiptDisplayCarousel
                          key={`receipt-display-for-${expenseId}`}
                          documents={[
                            selectedClaim?.receipt_image,
                            ...selectedClaim.supporting_documents,
                          ]}
                        />
                      )}
                      {selectedClaim?.receipt_image && props.editMode && (
                        <ComptReceiptFormCarousel
                          key={`receipt-form-for-${expenseId}`}
                          formMethods={formMethods}
                          receiptLabel="Uploaded receipt"
                          userId={userId}
                          initialReceiptValue={selectedClaim?.receipt_image}
                          initialSupportingDocs={selectedClaim?.supporting_documents}
                          expenseId={expenseId}
                        />
                      )}
                    </div>
                  </div>
                  <div ref={contentRef} className="w-full py-6 px-6 sm:overflow-y-auto">
                    {selectedClaim?.rejection_reason && (
                      <ComptRejectionReason rejectionReason={selectedClaim.rejection_reason} />
                    )}
                    <StipendFormContent
                      formMethods={formMethods}
                      allotments={selectableAllotments}
                      disabled={!props.editMode}
                      userCountryCode={sessionQuery?.data?.country}
                      stipendCurrency={selectedClaim?.currency}
                      editMode={props.editMode}
                    />
                    {selectedClaim?.amount_claimed && (
                      <p className="body3 text-color-body2 -mt-4">
                        Amount covered:{' '}
                        {formatCurrencyFromCountryCode(
                          selectedClaim?.amount_claimed,
                          sessionQuery.data?.country,
                          selectedClaim?.currency,
                        )}
                      </p>
                    )}
                    {selectedClaim?.formatted_history && allPerkCategories && (
                      <ClaimEditHistory
                        formattedHistory={selectedClaim?.formatted_history}
                        allPerkCategories={allPerkCategories}
                        rejectionReason={selectedClaim?.rejection_reason}
                      />
                    )}
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </ComptSidePanel.Content>
        <ComptSidePanel.Footer>
          <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
            {selectedClaim?.status === ExpenseStatus.Open && !props.editMode && (
              <>
                <ComptButton buttonType={ComptButtonType.SECONDARY} onClick={beginEdit}>
                  Edit
                </ComptButton>
                <ComptButton
                  buttonType={ComptButtonType.DESTRUCTIVE}
                  onClick={() => {
                    if (selectedClaim) {
                      setExpenseToDelete(selectedClaim);
                    }
                  }}
                >
                  Delete
                </ComptButton>
              </>
            )}
            {props.editMode && (
              <>
                <ComptButton
                  onClick={formMethods.handleSubmit((payload) => onSubmitSaveClaim(payload))}
                  disabled={isUpdating || receiptUploading}
                >
                  Submit
                </ComptButton>
                <ComptButton
                  buttonType={ComptButtonType.SECONDARY}
                  onClick={() => {
                    props.setEditMode(() => false);
                    setReceiptUploading(() => false);
                  }}
                  disabled={isUpdating}
                >
                  Cancel
                </ComptButton>
              </>
            )}
            {selectedClaim?.status !== ExpenseStatus.Open && !props.editMode && (
              <ComptButton buttonType={ComptButtonType.SECONDARY} onClick={clearSelectedClaim}>
                Close
              </ComptButton>
            )}
          </div>
        </ComptSidePanel.Footer>
      </ComptSidePanel>
    </>
  );
};
