import React, {useMemo, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetHistoricalBusinessExpensesQuery} from '@compt/app/services/api/admin-business-expenses-slice';

// Hooks and methods
import {useDebounce} from '@uidotdev/usehooks';
import {AdminSearchBEPageController} from './admin-search-business-expenses-page.controller';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {AdminSearchBusinessExpenseSidePanel} from './components/admin-search-business-expense-side-panel';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';

// Types
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {MAX_BUSINESS_EXPENSES_PER_PAGE} from '@compt/constants';
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {USER_ROLES} from '@compt/utils/user-roles-helper';

const controller = AdminSearchBEPageController;

export const AdminSearchBusinessExpensesPage = () => {
  const [selectedExpense, setSelectedExpense] = useState<BusinessExpense | null>(null);

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);

  const isFinancialReviewer = sessionQuery.data?.roles.includes(USER_ROLES.financeReviewer);
  const isEmployeeManager = sessionQuery.data?.roles.includes(USER_ROLES.employeeManager);
  const hasRequiredRole = isFinancialReviewer || isEmployeeManager;

  const [reportsQueryValues, setReportsQueryValues] = useState<FormattedFilterObject | undefined>();
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const debouncedQueryValues = useDebounce(reportsQueryValues, 300);

  const historicalBusinessExpenseQuery = useGetHistoricalBusinessExpensesQuery(
    {companyId: companyQuery.data?.id, params: debouncedQueryValues},
    {skip: !companyQuery.data?.id},
  );

  const columnDefinition = controller.getColumnDefinition((expense: BusinessExpense) => {
    setSelectedExpense(expense);
  });
  const filterConfiguration = controller.getFilterConfiguration();
  const initialFilterValues = controller.getInitialFilterValues();

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No expenses were found with the current filters.'
        : 'No expenses have been added yet',
    [filtersApplied],
  );

  if (
    sessionQuery.isError ||
    companyQuery.isError ||
    historicalBusinessExpenseQuery.isError ||
    !hasRequiredRole
  ) {
    return <Error404Page />;
  }

  if (!historicalBusinessExpenseQuery.data || !companyQuery.data) return null;

  return (
    <>
      <ComptPage
        title="Search business expenses"
        subtitle="Search and view business expenses"
        className="h-dvh"
        includeBottomHR={false}
      >
        <AdminSearchBusinessExpenseSidePanel
          selectedExpense={selectedExpense}
          setSelectedExpense={setSelectedExpense}
        />
        <ComptLoadingIndicator
          isLoading={companyQuery.isLoading || historicalBusinessExpenseQuery.isLoading}
          className="pt-72"
        >
          <ComptTable
            company={companyQuery.data}
            tableId="admin-search-business-expense-table"
            className="w-full"
            data={historicalBusinessExpenseQuery.data?.results ?? []}
            dataLoading={companyQuery.isFetching || historicalBusinessExpenseQuery.isFetching}
            noDataTitleText={noDataTitleText}
            allowShowHide={false}
            columnDefinition={columnDefinition}
            filterConfiguration={filterConfiguration}
            initialFilterValues={initialFilterValues}
            allowPagination={true}
            totalCount={historicalBusinessExpenseQuery.data?.count}
            itemsPerPage={MAX_BUSINESS_EXPENSES_PER_PAGE}
            stickyLastColumn
            showColumnFilters
            onChangeQueryValues={(filterValues, pagination, ordering) =>
              controller.updateQueryParamsOnValuesChanged(
                companyQuery.data?.id,
                setReportsQueryValues,
                setFiltersApplied,
                pagination,
                filterValues,
                ordering,
              )
            }
          />
        </ComptLoadingIndicator>
      </ComptPage>
    </>
  );
};
