import {
  SideNavItem,
  SideNavItemKeys,
  SideNavItemType,
} from '../compt-side-nav/compt-side-nav.types';
import {USER_ROLES} from '../../utils/user-roles-helper';
import {FeatureFlags, featureEnabled} from '@compt/utils/feature-flags-helper';
import {UserSession} from '@compt/types/account';
import {BaseIconName} from '../compt-side-nav/compt-side-nav-button';

export enum SECTION_TYPE {
  EMPLOYEE = 'EMPLOYEE',
  STIPEND_ADMIN = 'STIPEND_ADMIN',
  EXPENSE_ADMIN = 'EXPENSE_ADMIN',
  INTERNAL_ADMIN = 'INTERNAL_ADMIN',
}

type ItemType = {
  sideNavItem: SideNavItemType;
  eligibleUserRoles: string[];
  viewableByHijacker?: boolean;
  requiredFeature?: FeatureFlags;
};

export type SectionType = {
  title: string;
  type: SECTION_TYPE;
  isCollapsible: boolean;
  eligibleUserRoles: string[];
  viewableByHijacker: boolean;
  items: ItemType[];
  requiredFeature?: FeatureFlags;
  sectionIcon?: string;
};

const isAdminNDSEnabled = featureEnabled(FeatureFlags.ENABLE_ADMIN_NDS);
function getAdminNDSPagePath(onDjangoTemplatePage: boolean) {
  if (isAdminNDSEnabled && onDjangoTemplatePage)
    return {
      be_review_page_path: '/app/#/review-business-expenses',
      be_search_page_path: '/app/#/search-business-expenses',
      custom_notifications_path: '/app/#/customizable-notifications',
    };
  if (isAdminNDSEnabled && !onDjangoTemplatePage)
    return {
      be_review_page_path: '/review-business-expenses',
      be_search_page_path: '/search-business-expenses',
      custom_notifications_path: '/customizable-notifications',
    };

  return {
    be_review_page_path: '/manage/#/review-business-expenses',
    be_search_page_path: '/manage/#/search-expenses',
    custom_notifications_path: '/manage/#/customizable-notifications',
  };
}

export class ComptLayoutController {
  getEligibleNavSectionsAndItems(
    userDataState?: UserSession,
    onDjangoTemplatePage?: boolean,
  ): SectionType[] | null {
    if (!userDataState) {
      return null;
    }

    const {is_hijacked, hijacker_is_superuser, roles} = userDataState;
    const navRoleDefinitions = getNavRoleDefinitions(onDjangoTemplatePage);

    if (is_hijacked) {
      return navRoleDefinitions
        .filter(
          (section) =>
            (section.viewableByHijacker || hijacker_is_superuser) &&
            this.isFeatureEnabled(section) &&
            section.eligibleUserRoles.some((role) => roles.includes(role)),
        )
        .map((section) => ({
          ...section,
          items: section.items.filter(
            (item) =>
              (item.viewableByHijacker || hijacker_is_superuser) &&
              this.isFeatureEnabled(item) &&
              item.eligibleUserRoles.some((role) => roles.includes(role)),
          ),
        }));
    }

    return navRoleDefinitions
      .filter(
        (section) =>
          section.eligibleUserRoles.some((role) => roles.includes(role)) &&
          this.isFeatureEnabled(section),
      )
      .map((section) => ({
        ...section,
        items: section.items.filter(
          (item) =>
            item.eligibleUserRoles.some((role) => roles.includes(role)) &&
            this.isFeatureEnabled(item),
        ),
      }));
  }

  private isFeatureEnabled(sectionOrNavItem: ItemType | SectionType) {
    return (
      sectionOrNavItem.requiredFeature === undefined ||
      featureEnabled(sectionOrNavItem.requiredFeature)
    );
  }
}

function getNavRoleDefinitions(onDjangoTemplatePage?: boolean): SectionType[] {
  return [
    {
      title: 'Employee',
      type: SECTION_TYPE.EMPLOYEE,
      isCollapsible: false,
      eligibleUserRoles: [
        USER_ROLES.stipendEligible,
        USER_ROLES.businessExpenseEligible,
        USER_ROLES.delegate,
      ],
      viewableByHijacker: true,
      items: [
        {
          viewableByHijacker: true,
          sideNavItem: {
            key: SideNavItemKeys.MY_STIPEND,
            title: 'My stipends',
            iconId: BaseIconName.BANK_NOTE,
            pagePath: onDjangoTemplatePage ? '/app/#/' : '/',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.ACTIVITY,
            title: 'Stipend activity',
            iconId: BaseIconName.ACTIVITY,
            pagePath: onDjangoTemplatePage ? '/app/#/activity/' : '/activity',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BUSINESS_EXPENSE,
            title: 'Business expenses',
            iconId: BaseIconName.BRIEFCASE,
            pagePath: onDjangoTemplatePage ? '/app/#/business-expenses/' : '/business-expenses',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.businessExpenseEligible],
          requiredFeature: FeatureFlags.BUSINESS_EXPENSES,
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.TEAM_RECOGNITION,
            title: 'Team recognition',
            iconId: BaseIconName.AWARD,
            pagePath: onDjangoTemplatePage ? '/app/#/team-recognition/' : '/team-recognition',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible],
          viewableByHijacker: true,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.FAQ,
            title: 'FAQs',
            iconId: BaseIconName.ANNOTATION_QUESTION,
            pagePath: onDjangoTemplatePage ? '/app/#/faq' : '/faq',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.stipendEligible, USER_ROLES.businessExpenseEligible],
          viewableByHijacker: false,
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.ASSIGNMENTS,
            title: 'Assignments',
            iconId: BaseIconName.CHECK_DONE,
            pagePath: onDjangoTemplatePage ? '/app/#/accounts/delegate' : '/accounts/delegate',
            isHref: onDjangoTemplatePage,
          },
          eligibleUserRoles: [USER_ROLES.delegate],
          viewableByHijacker: false,
        },
      ],
    },
    {
      title: 'Stipend admin',
      type: SECTION_TYPE.STIPEND_ADMIN,
      isCollapsible: true,
      sectionIcon: 'wallet-icon-gray',
      eligibleUserRoles: [USER_ROLES.stipendProgramManager, USER_ROLES.stipendReviewer],
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_DASHBOARD,
          eligibleUserRoles: [USER_ROLES.stipendProgramManager, USER_ROLES.auditor],
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_STIPENDS,
          eligibleUserRoles: [USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_TEAM,
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_TEAM_SYNC_LOGS,
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.stipendProgramManager],
          requiredFeature: FeatureFlags.TEAM_ROSTER_SYNC_LOGS,
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_PAYROLL,
          eligibleUserRoles: [USER_ROLES.payrollProcessor, USER_ROLES.stipendReviewer],
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_FAQ,
          eligibleUserRoles: [USER_ROLES.stipendProgramManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS,
            title: 'Notifications',
            iconId: BaseIconName.PIE_CHART,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).custom_notifications_path,
            isHref: onDjangoTemplatePage || !isAdminNDSEnabled,
          },
          eligibleUserRoles: [USER_ROLES.admin],
        },
        {
          sideNavItem: SideNavItem.STIPEND_ADMIN_COMPANY_SETTINGS,
          eligibleUserRoles: [USER_ROLES.admin],
        },
      ],
    },
    {
      title: 'Expense admin',
      type: SECTION_TYPE.EXPENSE_ADMIN,
      isCollapsible: true,
      sectionIcon: 'coin-stacked-icon-gray',
      eligibleUserRoles: [
        USER_ROLES.businessExpenseProgramManager,
        USER_ROLES.employeeManager,
        USER_ROLES.financeReviewer,
      ],
      requiredFeature: FeatureFlags.BUSINESS_EXPENSES,
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: SideNavItem.BE_ADMIN_REPORTING,
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.auditor],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_REVIEW_EXPENSES,
            title: 'Review expenses',
            iconId: BaseIconName.CHECK_DONE,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).be_review_page_path,
            isHref: onDjangoTemplatePage || !isAdminNDSEnabled,
          },
          eligibleUserRoles: [USER_ROLES.financeReviewer, USER_ROLES.employeeManager],
        },
        {
          sideNavItem: {
            key: SideNavItemKeys.BE_ADMIN_SEARCH_EXPENSES,
            title: 'Search expenses',
            iconId: BaseIconName.SEARCH_REFRACTION,
            pagePath: getAdminNDSPagePath(!!onDjangoTemplatePage).be_search_page_path,
            isHref: onDjangoTemplatePage || !isAdminNDSEnabled,
          },
          eligibleUserRoles: [USER_ROLES.financeReviewer, USER_ROLES.employeeManager],
        },
        {
          sideNavItem: SideNavItem.BE_ADMIN_TEAM,
          eligibleUserRoles: [USER_ROLES.admin, USER_ROLES.businessExpenseProgramManager],
        },
        {
          sideNavItem: SideNavItem.BE_ADMIN_PAYROLL,
          eligibleUserRoles: [USER_ROLES.payrollProcessor],
        },
        {
          sideNavItem: SideNavItem.BE_ADMIN_SETTINGS,
          eligibleUserRoles: [USER_ROLES.admin],
        },
      ],
    },
    {
      title: 'Internal admin',
      type: SECTION_TYPE.INTERNAL_ADMIN,
      isCollapsible: true,
      sectionIcon: 'settings-icon-gray',
      eligibleUserRoles: [USER_ROLES.internalAdmin],
      viewableByHijacker: false,
      items: [
        {
          sideNavItem: SideNavItem.DJANGO_ADMIN,
          eligibleUserRoles: [USER_ROLES.internalAdmin],
        },
        {
          sideNavItem: SideNavItem.COMPT_ADMIN,
          eligibleUserRoles: [USER_ROLES.internalAdmin],
        },
      ],
    },
  ];
}
