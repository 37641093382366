import React, {ReactNode, useState} from 'react';
import {ComptSideNav} from '../compt-side-nav/compt-side-nav';
import {ComptLayoutController} from './compt-layout.controller';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {ComptTopBar} from '../compt-top-bar/compt-top-bar';
import {SideNavContext} from '../compt-side-nav/side-nav-context';
import {ComptBanner, ComptBannerType} from '@compt/common/compt-banner/compt-banner';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {useReleaseUserMutation} from '@compt/app/services/api/delegate-slice';
import {UserSession} from '@compt/types/account';
import {NoPermissionsPage} from '@compt/pages/no-permissions-page/no-permissions-page';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {Transition} from '@headlessui/react';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';

export type ComptLayoutProps = {
  children: ReactNode;
  onboardingRequired: boolean;
};

interface HijackBannerProps {
  userData: UserSession;
  handleReleaseHijack: () => void;
  loading: boolean;
}

const HijackBanner = ({userData, handleReleaseHijack, loading}: HijackBannerProps) => {
  const isMobileView = useIsMobileView();

  return (
    <ComptBanner
      bannerType={ComptBannerType.WARNING}
      message={
        <div className="flex flex-row">
          {!isMobileView && (
            <div
              className={`grid place-items-center rounded-full border-black
            border-2 border-opacity-10 w-[36px] h-[36px]`}
            >
              <ComptSvgIcon
                iconName={'alert-circle-black-icon'}
                className={`grid place-items-center rounded-full border-black
                  border-2 border-opacity-30	w-[28px] h-[28px]`}
              />
            </div>
          )}
          {userData && (
            <div className="flex items-center">
              <span className="ml-2 align-middle">
                <b className="break-all">
                  You are currently working on behalf of {userData.email}.
                </b>{' '}
                Once finished, use the release button.
              </span>
            </div>
          )}
        </div>
      }
      renderAction={
        <ComptButton
          buttonType={ComptButtonType.OUTLINED_TRANSPARENT}
          onClick={() => handleReleaseHijack()}
          disabled={loading}
          size={ComptButtonSize.SMALL}
          className={`${isMobileView && 'mt-100'}`}
        >
          {loading ? 'RELEASING...' : 'RELEASE'}
        </ComptButton>
      }
    />
  );
};

export const ComptLayout = ({onboardingRequired, children}: ComptLayoutProps) => {
  const controller = new ComptLayoutController();
  const session = useGetSessionQuery();
  const userData = session.data;

  const eligibleNavSections = controller.getEligibleNavSectionsAndItems(userData);
  const [releaseUserMutation, releaseUserMutationStatus] = useReleaseUserMutation();

  const isMobile = useIsMobileView();
  const isAdminView = false;
  const defaultSidebarOpen = isMobile ? false : true;

  const [sidebarOpen, setSidebarOpen] = useState(defaultSidebarOpen);
  const [stipendAdminOpen, setStipendAdminOpen] = useState(false);
  const [expenseAdminOpen, setExpenseAdminOpen] = useState(false);
  const [internalAdminOpen, setInternalAdminOpen] = useState(false);

  const [receiptUploading, setReceiptUploading] = useState(false);

  function containerClass() {
    const classNameBase = 'layout-main-content overflow-y-auto bg-white w-full';

    if (onboardingRequired) {
      return `${classNameBase} h-screen flex justify-center items-center`;
    }

    return `${classNameBase}`;
  }

  if (!eligibleNavSections || eligibleNavSections.length === 0) {
    return (
      <>
        {!onboardingRequired && <ComptTopBar />}
        {!onboardingRequired && isMobile && (
          <div className="absolute z-10 bg-white sm:flex sm:relative h-screen">
            <ComptSideNav navSections={[]} />
          </div>
        )}
        <NoPermissionsPage
          heading="Looks like you're not eligible for any benefits through Compt"
          description={
            'We recommend reaching out to your HR team for any questions or help getting set up!'
          }
        />
      </>
    );
  }

  const handleReleaseHijack = () => {
    if (session.data?.is_hijacked) {
      releaseUserMutation().then((result) => {
        if ('error' in result) {
          console.error('Error releasing user', result);
          triggerCustomToast('error', 'There was a problem releasing user', 'Please try again.');
        } else {
          triggerCustomToast('success', 'Successfully released user');
          // We want a full page refresh when this happens so use native location instead of React Router.
          window.location.replace('/');
        }
      });
    }
  };

  return (
    <SideNavContext.Provider
      value={{
        isAdminView,
        sidebarOpen,
        setSidebarOpen,
        stipendAdminOpen,
        setStipendAdminOpen,
        expenseAdminOpen,
        setExpenseAdminOpen,
        internalAdminOpen,
        setInternalAdminOpen,
      }}
    >
      <ReceiptUploadContext.Provider value={{receiptUploading, setReceiptUploading}}>
        <div className="flex flex-col h-screen">
          {session.data?.is_hijacked && userData && (
            <HijackBanner
              handleReleaseHijack={handleReleaseHijack}
              userData={userData}
              loading={releaseUserMutationStatus.isLoading || releaseUserMutationStatus.isSuccess}
            />
          )}

          {!onboardingRequired && <ComptTopBar />}
          <div className={`${onboardingRequired ? '' : 'flex flex-grow overflow-hidden'}`}>
            {!onboardingRequired && (
              <div className={'absolute sm:relative z-30 sm:z-20 sm:flex'}>
                <ComptSideNav navSections={eligibleNavSections} />
              </div>
            )}
            <div className={containerClass()}>
              {/* Apply background overlay when mobile sidebar is open */}
              {isMobile && (
                <Transition
                  show={isMobile && sidebarOpen}
                  as="div"
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="fixed z-20"
                >
                  <div className="fixed inset-0 z-20 bg-gray-900 bg-opacity-80 transition-opacity" />
                </Transition>
              )}
              {children}
            </div>
          </div>
        </div>
      </ReceiptUploadContext.Provider>
    </SideNavContext.Provider>
  );
};
