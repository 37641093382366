import React, {useEffect, useState} from 'react';
import DataTable, {ConditionalStyles} from 'react-data-table-component';
import {ComptTableController} from './compt-table.controller';
import {ComptFilterBar} from '../compt-filter-bar/compt-filter-bar.container';
import {ComptPaginationBar} from '../compt-pagination-bar/compt-pagination-bar';
import {ColumnDefinition, ComptTableProps} from './compt-table.types';
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';
import {SelectFilter} from '../compt-filter-bar/compt-select-filter';
import {SelectionChangeValues} from '../compt-filter-bar/compt-filter-bar.types';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import DOMPurify from 'dompurify';
import comptColors from '@compt/styles/compt-colors';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {ComptMobilePagination} from '@compt/common/compt-mobile-pagination/compt-mobile-pagination';
import {MAX_EXPENSES_PER_PAGE} from '@compt/pages/employee-stipends/activity-page/activity-table.controller';
import {twMerge} from 'tailwind-merge';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

export const ComptTable = <T,>(props: ComptTableProps<T>) => {
  const isMobileView = useIsMobileView();
  const controller = ComptTableController;
  const {ordering} = props;
  const columnList = controller.getColumnsList(props.columnDefinition as ColumnDefinition<unknown>);
  const initialVisibleColumns = controller.getVisibleColumns(columnList);

  const [currentFilterValues, setCurrentFilterValues] = useCacheableObjectState(
    generateLocalStorageKey(props.tableId, 'filter-values'),
    props.initialFilterValues,
    props.cacheTableState,
  );

  const [visibleColumns, setVisibleColumns] = useCacheableObjectState(
    generateLocalStorageKey(props.tableId, 'visible-columns'),
    initialVisibleColumns,
    props.cacheTableState,
  );

  const [currentPage, setCurrentPage] = useState(1);

  // NOTE: adding onChangeQueryValues as a dependency introduces an infinite loop.
  useEffect(() => {
    if (currentFilterValues) {
      props.onChangeQueryValues?.(currentFilterValues, null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilterValues, ordering]);

  // Add Column Filter to Filter Bar if `showColumnFilters` is true
  const customCollapsibleFilters = [];
  if (props.showColumnFilters) {
    customCollapsibleFilters.push(
      <SelectFilter
        label="Columns"
        currentValues={controller.getSelectedColumnOptions(
          props.columnDefinition as ColumnDefinition<unknown>,
          visibleColumns,
        )}
        options={controller.getColumnOptions(columnList)}
        handleChange={(change: SelectionChangeValues) => {
          controller.handleColumnsChange(change, visibleColumns, setVisibleColumns);
        }}
      />,
    );
  }

  const onPageSelectedCallback = (page: number) =>
    controller.handlePageChange(
      page,
      props as ComptTableProps<unknown>,
      setCurrentPage,
      currentFilterValues,
    );

  const NoDataComponent = (
    <div className="flex flex-col items-center m-40">
      <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
      <p
        className="heading4 inner-html text-center"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.noDataTitleText),
        }}
      />
      {props.noDataSubtitle}
    </div>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: comptColors['surface-tint'],
        color: comptColors.gray['800'],
        fontSize: '14px',
        fontWeight: '600',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        color: comptColors.gray['700'],
      },
    },
    table: {
      style: {
        borderBottomRightRadius: '0.75rem',
      },
    },
  };

  const tableClassNames = [props.tableId];

  if (props.onRowClicked) {
    tableClassNames.push('compt-table-clickable-rows');
  }
  if (props.stickyLastColumn) {
    tableClassNames.push('compt-table-sticky-last-column');
  }
  if (props.data.length === 0) {
    tableClassNames.push('compt-empty-data-table');
  }

  return (
    <div className="border rounded-xl">
      <div className="flex justify-between">
        {props.title && <h2 className="compt-text--semi-bold">{props.title}</h2>}
        {props.controlsComponent && props.controlsComponent}
      </div>
      {currentFilterValues && props.filterConfiguration && setCurrentFilterValues && (
        <>
          <ComptFilterBar
            filterValues={currentFilterValues}
            filterConfig={props.filterConfiguration}
            onFilterChanged={(change) =>
              controller._filterChanged(change, props.filterConfiguration, setCurrentFilterValues)
            }
            customCollapsibleFilters={customCollapsibleFilters}
          />
          <hr />
        </>
      )}
      <div className={twMerge(`rounded-xl ${props.className}`)} id={props.tableId}>
        <DataTable
          columns={controller.getFilteredColumnList(columnList, visibleColumns)}
          style={{whiteSpace: 'normal'}}
          className={`compt-data-table ${tableClassNames.join(' ')}`}
          customStyles={customStyles}
          fixedHeader={props.fixedHeader ?? isMobileView}
          data={props.data}
          noDataComponent={NoDataComponent}
          pagination={props.allowPagination}
          paginationTotalRows={props.totalCount}
          paginationComponent={(pageProps) => (
            <>
              {isMobileView ? (
                <div>
                  <ComptMobilePagination
                    {...pageProps}
                    itemsPerPage={props.itemsPerPage || MAX_EXPENSES_PER_PAGE}
                    totalPages={controller.getTotalPages(
                      props.totalCount,
                      props.itemsPerPage || MAX_EXPENSES_PER_PAGE,
                    )}
                    currentPage={currentPage}
                    onChangePage={onPageSelectedCallback}
                  />
                </div>
              ) : (
                <ComptPaginationBar
                  {...pageProps}
                  totalPages={controller.getTotalPages(
                    props.totalCount,
                    props.itemsPerPage || MAX_EXPENSES_PER_PAGE,
                  )}
                  currentPage={currentPage}
                  itemsPerPage={props.itemsPerPage as number}
                  onChangePage={onPageSelectedCallback}
                />
              )}
            </>
          )}
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationPerPage={props.itemsPerPage}
          progressPending={props.dataLoading}
          progressComponent={
            <div className="h-[400px] sm:h-[700px]">
              <ComptLoadingIndicator isLoading={true} dependentData={false} className="h-full" />
            </div>
          }
          paginationDefaultPage={currentPage}
          paginationServer
          sortServer
          defaultSortFieldId={props?.orderingOptions?.fieldId}
          defaultSortAsc={props?.orderingOptions?.isAsc}
          conditionalRowStyles={props?.conditionalRowStyles as ConditionalStyles<unknown>[]}
          onRowClicked={props?.onRowClicked}
          selectableRows={props.selectableRows}
          onSelectedRowsChange={props.onSelectedRowsChange}
          selectableRowDisabled={props.selectableRowDisabled}
          clearSelectedRows={props.clearSelectedRows}
        />
      </div>
    </div>
  );
};
